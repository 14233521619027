<template>
  <div>
    <div class="d-flex flex-column justify-between h-100">
      <p class="title">Disparar</p>

      <div class="options">
        <div
          class="option"
          :class="{
            active: firingType === 'ALL_DAYS',
          }"
          @click="setFiringAllDays()"
        >
          <base-radio
            family="firing"
            option="ALL_DAYS"
            v-model="firingType"
            @click.prevent
          />
          <i class="fal fa-calendar-days icon" />
          <p class="name">Todos os dias</p>
        </div>

        <div
          class="option"
          :class="{ active: firingType === 'DAYS_WEEK' }"
          @click="openDrawer('DAYS_WEEK')"
        >
          <base-radio
            family="firing"
            option="DAYS_WEEK"
            v-model="firingType"
            @click.prevent
          />
          <i class="fal fa-calendar-day icon" />
          <p class="name">Em dias específicos da semana</p>
        </div>

        <div
          class="option"
          :class="{ active: firingType === 'DAYS_MONTH' }"
          @click="openDrawer('DAYS_MONTH')"
        >
          <base-radio
            family="firing"
            option="DAYS_MONTH"
            v-model="firingType"
            @click.prevent
          />
          <i class="fal fa-calendar icon" />
          <p class="name">Em dias específicos do mês</p>
        </div>
      </div>
    </div>

    <base-drawer
      :open="drawer.open"
      :close="drawer.close"
      :title="selectedFiring.title"
    >
      <recurring-in-week
        v-if="selectedFiring.name === 'recurringInWeek'"
        @validateDrawer="validateOn"
        :campaign="campaign"
      />
      <recurring-in-month
        v-if="selectedFiring.name === 'recurringInMonth'"
        @validateDrawer="validateOn"
        :campaign="campaign"
      />

      <template slot="footer">
        <base-button
          text="Aplicar"
          class="w-100"
          @click="applyConfig"
          :disabled="!valid"
        />
      </template>
    </base-drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BaseDrawer from '@/components/Drawer';
import BaseRadio from '@/components/Radio';
import BaseButton from '@/components/Button';
import RecurringInWeek from '@/containers/Performance/SetpsValidator/Drawers/recurringInWeek.vue';
import RecurringInMonth from '@/containers/Performance/SetpsValidator/Drawers/recurringInMonth.vue';

const components = {
  BaseDrawer,
  BaseRadio,
  BaseButton,
  RecurringInWeek,
  RecurringInMonth,
};

const FIRING_OPTIONS = {
  ALL_DAYS: {
    title: 'Todos os dias',
    name: 'allDays',
    type: 'ALL_DAYS',
  },
  DAYS_WEEK: {
    title: 'Em dias específicos da semana',
    name: 'recurringInWeek',
    type: 'DAYS_WEEK',
  },
  DAYS_MONTH: {
    title: 'Em dias específicos do mês',
    name: 'recurringInMonth',
    type: 'DAYS_MONTH',
  },
};

export default {
  components,
  props: {
    campaign: {
      type: Object,
    },
  },
  mounted() {
    this.loadFiring(this.campaign);
  },
  data() {
    return {
      editConfigs: {},
      valid: false,
      drawer: { open: false, close: false },
      selectedFiring: {
        title: null,
        name: null,
        type: null,
      },
      firingType: null,
      configs: {},
    };
  },
  methods: {
    loadFiring(campaign) {
      if (campaign.configs.send_time && campaign.configs.send_time.days_week) {
        this.configs.days_week = campaign.configs.send_time.days_week;
        this.firingType = 'DAYS_WEEK';
      }

      if (campaign.configs.send_time && campaign.configs.send_time.days_month) {
        this.configs.days_month = campaign.configs.send_time.days_month;
        this.firingType = 'DAYS_MONTH';
      }

      if (campaign.configs.send_time && campaign.configs.send_time.all_days) {
        this.configs.all_days = campaign.configs.send_time.all_days;
        this.firingType = 'ALL_DAYS';
      }
    },
    setFiringAllDays() {
      this.firingType = 'ALL_DAYS';
      this.configs = {
        all_days: true,
      };

      this.$emit('onSaveFiring', this.configs);
    },
    applyConfig() {
      this.drawer.close = !this.drawer.close;

      this.firingType = this.editConfigs.type;
      this.configs = this.editConfigs.values;

      this.$emit('onSaveFiring', this.configs);
    },
    validateOn(value, payload) {
      this.valid = value;

      if (this.valid) this.editConfigs = payload;
    },
    openDrawer(item) {
      this.drawer.open = !this.drawer.open;

      this.selectedFiring.title = FIRING_OPTIONS[item].title;
      this.selectedFiring.name = FIRING_OPTIONS[item].name;
    },
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.store.isMobile,
    }),
  },
  watch: {
    campaign: {
      deep: true,
      handler(campaign) {
        this.loadFiring(campaign);
      },
    },
  },
};
</script>

<style scoped>
.options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid rgba(111, 118, 126, 0.1);
}

@media screen and (max-width: 991px) {
  .options {
    grid-template-columns: repeat(1, 1fr);
  }
}

.title {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #bebebf;
  margin-bottom: 20px;
}

.option {
  background: rgba(46, 52, 56, 0.35);
  border-radius: 10px;
  height: 76px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  border: 1px solid transparent;
}

.option.active {
  border: 1px solid rgba(97, 97, 255, 0.55);
}

.option.active .icon {
  background: #313550;
  color: var(--default);
}

.option .icon {
  background: rgba(54, 62, 66, 0.3);
  border-radius: 10px;
  width: 36px;
  height: 36px;
  margin-left: 5px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--icon-inactive);
  font-size: 15px;
}

.option .name {
  font-size: 12px;
  line-height: 14px;
  color: rgba(170, 170, 170, 0.8);
}
</style>
