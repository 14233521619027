<template>
  <div>
    <div class="d-flex flex-column justify-between h-100">
      <p class="title">Quando o clima estiver</p>

      <div class="options border-bottom">
        <div
          class="option"
          @click="toggleClimate('TEMPERATURE')"
          :class="{
            active: hasClimateCondition('temperature'),
          }"
        >
          <base-checkbox
            @click.prevent
            :checked="hasClimateCondition('temperature')"
          />
          <i class="fal fa-temperature-half icon" />
          <p class="name hover">Com temperatura entre</p>
        </div>

        <div
          class="option"
          @click="toggleClimate('MOISTURE')"
          :class="{
            active: hasClimateCondition('moisture'),
          }"
        >
          <base-checkbox
            @click.prevent
            :checked="hasClimateCondition('moisture')"
          />
          <i class="fal fa-droplet icon" />
          <p class="name hover">Com a umidade entre</p>
        </div>

        <div
          class="option"
          @click="toggleClimate('PROBAILITY_OF_RAIN')"
          :class="{
            active: hasClimateCondition('rain'),
          }"
        >
          <base-checkbox
            @click.prevent
            :checked="hasClimateCondition('rain')"
          />
          <i class="fal fa-cloud-drizzle icon" />
          <p class="name hover">Com a probabilidade de chuva entre</p>
        </div>
      </div>

      <p class="title">Quando a característica do clima for</p>

      <div class="options">
        <div
          class="option"
          @click="toggleWeather('SUNNY')"
          :class="{ active: hasWeatherType('SUNNY') }"
        >
          <base-checkbox :checked="hasWeatherType('SUNNY')" />
          <i class="fal fa-sun-bright icon" />
          <p class="name">Ensolarado</p>
        </div>

        <div
          class="option"
          @click="toggleWeather('PARTLY_CLOUDY')"
          :class="{ active: hasWeatherType('PARTLY_CLOUDY') }"
        >
          <base-checkbox :checked="hasWeatherType('PARTLY_CLOUDY')" />
          <i class="fal fa-cloud-sun icon" />
          <p class="name">Parcialmente nublado</p>
        </div>

        <div
          class="option"
          @click="toggleWeather('CLOUDY')"
          :class="{ active: hasWeatherType('CLOUDY') }"
        >
          <base-checkbox :checked="hasWeatherType('CLOUDY')" />
          <i class="fal fa-cloud icon" />
          <p class="name">Nublado</p>
        </div>

        <div
          class="option"
          @click="toggleWeather('RAINING')"
          :class="{ active: hasWeatherType('RAINING') }"
        >
          <base-checkbox :checked="hasWeatherType('RAINING')" />
          <i class="fal fa-cloud-hail-mixed icon" />
          <p class="name">Chovendo</p>
        </div>
      </div>
    </div>

    <base-drawer
      :open="drawer.open"
      :close="drawer.close"
      :title="drawer.name"
      @onClose="closeDrawer()"
    >
      <temperature
        v-if="drawer.type === 'TE'"
        @validateDrawer="validateOn"
        :climate="configs.climate"
      />
      <moisture
        v-if="drawer.type === 'MO'"
        @validateDrawer="validateOn"
        :climate="configs.climate"
      />
      <probability-of-rain
        v-if="drawer.type === 'PR'"
        @validateDrawer="validateOn"
        :climate="configs.climate"
      />

      <template slot="footer">
        <base-button
          text="Aplicar"
          class="w-100"
          @click="applyConfig"
          :disabled="!valid"
        />
      </template>
    </base-drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BaseDrawer from '@/components/Drawer';
import BaseCheckbox from '@/components/Checkbox';
import BaseButton from '@/components/Button';
import Temperature from '@/containers/Performance/SetpsValidator/Drawers/Climate/Temperature.vue';
import Moisture from '@/containers/Performance/SetpsValidator/Drawers/Climate/Moisture.vue';
import ProbabilityOfRain from '@/containers/Performance/SetpsValidator/Drawers/Climate/ProbabilityOfRain.vue';

const components = {
  BaseDrawer,
  BaseCheckbox,
  BaseButton,
  Temperature,
  Moisture,
  ProbabilityOfRain,
};

const CLIMATE_OPTIONS = {
  TEMPERATURE: {
    name: 'Temperatura',
    type: 'TE',
  },
  MOISTURE: {
    name: 'Umidade',
    type: 'MO',
  },
  PROBAILITY_OF_RAIN: {
    name: 'Probabilidade de chuva',
    type: 'PR',
  },
};

export default {
  components,
  props: {
    campaign: {
      type: Object,
    },
  },
  created() {
    this.configs = {
      climate: this.campaign.configs.climate,
      weather: this.campaign.configs.weather,
    };
  },
  data() {
    return {
      valid: false,
      editConfigs: {},
      configs: {
        climate: {
          temperature: {
            min: '',
            max: '',
          },
          moisture: {
            min: '',
            max: '',
          },
          rain: {
            min: '',
            max: '',
          },
        },
        weather: ['SUNNY', 'PARTLY_CLOUDY', 'CLOUDY', 'RAINING'],
      },
      drawer: { name: null, open: false, close: false },
    };
  },
  methods: {
    applyConfig() {
      this.drawer.close = !this.drawer.close;

      this.configs.climate = {
        ...(this.configs.climate || {}),
        [this.editConfigs.type]: this.editConfigs.values,
      };
      this.$emit('onSaveClimate', this.configs.climate);
    },

    validateOn(value, payload) {
      this.valid = value;

      if (this.valid) this.editConfigs = payload;
    },

    hasWeatherType(weatherType) {
      const currentWeather = this.configs.weather || [];
      return currentWeather.includes(weatherType);
    },

    hasClimateCondition(condition) {
      const field =
        this.configs.climate && this.configs.climate[condition]
          ? this.configs.climate[condition]
          : {};
      const { min, max } = field;

      if (min === null || min === undefined) {
        return false;
      }

      if (max === null || max === undefined) {
        return false;
      }

      return true;
    },

    toggleWeather(weatherType) {
      if (this.hasWeatherType(weatherType)) {
        this.configs.weather = this.configs.weather.filter(
          (weather) => weather !== weatherType
        );
      } else {
        this.configs.weather = [...this.configs.weather, weatherType];
      }

      this.$emit('onSaveWeather', this.configs.weather);
    },

    toggleClimate(climateCondition) {
      const conditions = JSON.parse(JSON.stringify(this.configs.climate || {}));
      const conditionProp = climateCondition.toLowerCase();
      if (conditions[conditionProp]) {
        delete conditions[conditionProp];
        this.configs.climate =
          Object.keys(conditions).length > 0 ? conditions : null;
        this.$emit('onSaveClimate', this.configs.climate);
      } else {
        this.openDrawer(climateCondition);
      }
    },

    openDrawer(item) {
      this.drawer.open = !this.drawer.open;

      this.drawer.name = CLIMATE_OPTIONS[item].name;
      this.drawer.type = CLIMATE_OPTIONS[item].type;
    },
    closeDrawer() {
      this.drawer.close = !this.drawer.close;
    },
  },
  watch: {
    campaign: {
      deep: true,
      handler(campaign) {
        this.configs = {
          climate: campaign.configs.climate,
          weather: campaign.configs.weather,
        };
      },
    },
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.store.isMobile,
    }),
  },
};
</script>

<style scoped>
.options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.options.border-bottom {
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid rgba(111, 118, 126, 0.1);
}

@media screen and (max-width: 991px) {
  .options {
    grid-template-columns: repeat(1, 1fr);
  }
}

.title {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #bebebf;
  margin-bottom: 20px;
}

.option {
  background: rgba(46, 52, 56, 0.35);
  border-radius: 10px;
  height: 76px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  border: 1px solid transparent;
}

.option.active {
  border: 1px solid rgba(97, 97, 255, 0.55);
}

.option.active .icon {
  background: #313550;
  color: var(--default);
}

.option .icon {
  background: rgba(54, 62, 66, 0.3);
  border-radius: 10px;
  width: 36px;
  height: 36px;
  margin-left: 5px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--icon-inactive);
  font-size: 15px;
}

.option .name {
  font-size: 12px;
  line-height: 14px;
  color: rgba(170, 170, 170, 0.8);
}

.option .name.hover {
  border-bottom: 1px solid transparent;
  padding-bottom: 2px;
  margin-top: 2px;
}

.option .name.hover:hover {
  color: rgb(170 170 170);
  border-bottom: 1px solid var(--label-primary);
  cursor: pointer;
}
</style>
