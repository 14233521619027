<template>
  <div>
    <div class="d-flex flex-column justify-between h-100">
      <p class="title">O usuário pode ser contemplado</p>

      <div class="options">
        <div
          class="option"
          :class="{ active: configs.single_conversion === false }"
          @click="openDrawer()"
        >
          <base-radio
            family="contemplation"
            :option="false"
            @click.prevent
            :value="configs.single_conversion"
          />
          <i class="fal fa-repeat icon" />
          <p class="name">Várias vezes</p>
        </div>

        <div
          class="option"
          :class="{ active: configs.single_conversion }"
          @click="setSingleConversion()"
        >
          <base-radio
            family="contemplation"
            :option="true"
            @click.prevent
            :value="configs.single_conversion"
          />
          <i class="fal fa-circle-1 icon" />
          <p class="name">Uma única vez</p>
        </div>
      </div>
    </div>

    <base-drawer
      :open="drawer.open"
      :close="drawer.close"
      title="Intervalo de contemplação"
    >
      <contemplation
        @validateDrawer="validateOn"
        :interval="configs.interval"
      />

      <template slot="footer">
        <base-button
          text="Aplicar"
          class="w-100"
          @click="applyConfig"
          :disabled="!valid"
        />
      </template>
    </base-drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BaseDrawer from '@/components/Drawer';
import BaseRadio from '@/components/Radio';
import BaseButton from '@/components/Button';
import Contemplation from '@/containers/Performance/SetpsValidator/Drawers/contemplation.vue';

const components = {
  BaseDrawer,
  BaseRadio,
  BaseButton,
  Contemplation,
};

export default {
  components,
  props: {
    campaign: Object,
  },
  mounted() {
    this.loadContemplated(this.campaign);
  },
  data() {
    return {
      payload: {},
      valid: false,
      drawer: { open: false, close: false },
      editConfigs: {},
      contemplationType: null,
      configs: {
        interval: null,
        single_conversion: null,
      },
    };
  },
  methods: {
    loadContemplated(campaign) {
      this.configs.interval = campaign.interval;
      this.configs.single_conversion = campaign.single_conversion;
    },
    setSingleConversion() {
      this.configs.single_conversion = true;
      this.configs.interval = null;
      this.$emit('onSaveContemplated', this.configs);
    },

    setMultipleConversion(interval) {
      this.configs.single_conversion = false;
      this.configs.interval = interval;
      this.$emit('onSaveContemplated', this.configs);
    },

    applyConfig() {
      this.drawer.close = !this.drawer.close;
      this.setMultipleConversion(this.editConfigs.values.interval);
    },

    validateOn(valid, payload) {
      this.valid = valid;
      if (valid) this.editConfigs = payload;
    },

    openDrawer() {
      this.valid = false;
      this.drawer.open = !this.drawer.open;
    },
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.store.isMobile,
    }),
  },
  watch: {
    campaign: {
      deep: true,
      handler(campaign) {
        this.loadContemplated(campaign);
      },
    },
  },
};
</script>

<style scoped>
.options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  padding-bottom: 25px;
  margin-bottom: 25px;
}

@media screen and (max-width: 991px) {
  .options {
    grid-template-columns: repeat(1, 1fr);
  }
}

.title {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #bebebf;
  padding-top: 25px;
  margin-top: 25px;
  border-top: 1px solid rgba(111, 118, 126, 0.1);
  margin-bottom: 20px;
}

.option {
  background: rgba(46, 52, 56, 0.35);
  border-radius: 10px;
  height: 76px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  border: 1px solid transparent;
}

.option.active {
  border: 1px solid rgba(97, 97, 255, 0.55);
}

.option.active .icon {
  background: #313550;
  color: var(--default);
}

.option .icon {
  background: rgba(54, 62, 66, 0.3);
  border-radius: 10px;
  width: 36px;
  height: 36px;
  margin-left: 5px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--icon-inactive);
  font-size: 15px;
}

.option .name {
  font-size: 12px;
  line-height: 14px;
  color: rgba(170, 170, 170, 0.8);
}
</style>
